<script>
/***
 Name: postman
 Code: sa0ChunLuyu
 Time: 2021/10/15 23:50
 Remark: Post测试页面
 */
import Clipboard from 'clipboard'
import QuickData from './quickData.vue'

export default {
  components: {QuickData},
  data() {
    return {
      quick_data_show: false,
      response_table: [],
      history_list: [],
      url_input: '/api/yo',
      post_remark: '测试接口',
      response: '{}',
      value_type: [{
        title: '字符串',
        value: 'String'
      }, {
        title: '对象',
        value: 'Object'
      }, {
        title: '数组',
        value: 'Array'
      }, {
        title: '布尔值',
        value: 'Boolean'
      }, {
        title: '数值',
        value: 'Number'
      }],
      response_content: '{}',
      data_list: [],
      create_data: {
        check: true,
        key: '',
        value: '',
        type: 'String',
        remark: ''
      },
      url_choose: 0,
      url_list: [{
        name: '鹿和通用',
        url: this.$api('')
      }]
    }
  },
  mounted() {
    this.getHistory()
  },
  methods: {
    quickDataOk(data) {
      let d = JSON.parse(data)
      let d_ = []
      for (let i in d) {
        let v = d[i]
        let t = typeof v
        t = t.slice(0, 1).toUpperCase() + t.slice(1)
        if (t === 'Object') {
          if (Array.isArray(v)) t = 'Array'
          v = JSON.stringify(v)
        }
        if (v === 'false') t = 'Boolean'
        if (v === 'true') t = 'Boolean'
        d_.push({
          check: true,
          key: i,
          value: v,
          type: t,
          remark: ''
        })
      }
      this.data_list = d_
      this.quick_data_show = false
    },
    quickDataCancel() {
      this.quick_data_show = false
    },
    copyClick(key) {
      const clipboard = new Clipboard(`.${key}`)
      clipboard.on('success', e => {
        this.saveHistory()
        layer.msg('复制成功')
        clipboard.destroy()
      })
      clipboard.on('error', e => {
        clipboard.destroy()
      })
    },
    historyItemClick(item) {
      this.response = item.response
      this.response_content = JSON.stringify(this.response, null, 4)
      this.url_input = item.url
      this.post_remark = item.remark
      this.data_list = item.data
      this.response_table = item.response_table ? item.response_table : []
    },
    deleteTableClick(key, k) {
      this.response_table[key].data.splice(k, 1);
    },
    downTableClick(key, k) {
      let data = this.response_table[key].data[k]
      this.$set(this.response_table[key].data, k, this.response_table[key].data[k + 1])
      this.$set(this.response_table[key].data, k + 1, data)
    },
    upTableClick(key, k) {
      let data = this.response_table[key].data[k]
      this.$set(this.response_table[key].data, k, this.response_table[key].data[k - 1])
      this.$set(this.response_table[key].data, k - 1, data)
    },
    upDataClick(key) {
      let data = this.data_list[key]
      this.$set(this.data_list, key, this.data_list[key - 1])
      this.$set(this.data_list, key - 1, data)
    },
    downDataClick(key) {
      let data = this.data_list[key]
      this.$set(this.data_list, key, this.data_list[key + 1])
      this.$set(this.data_list, key + 1, data)
    },
    deleteDataClick(key) {
      this.data_list.splice(key, 1);
    },
    createDataClick() {
      if (this.create_data.key === '') return;
      this.data_list.push(this.create_data)
      this.create_data = {
        check: true,
        key: '',
        value: '',
        type: 'String',
        remark: ''
      }
    },
    getHistory() {
      let history_list = this.$sa0.localStorage.get('POST_HISTORY')
      history_list = history_list ? history_list : []
      if (history_list.length > 0) {
        this.historyItemClick(history_list[0])
      }
      this.history_list = history_list
    },
    saveHistory() {
      let history_list = this.history_list
      history_list.unshift({
        url: this.url_input,
        data: this.data_list,
        response: this.response,
        remark: this.post_remark,
        response_table: this.response_table,
      })
      if (history_list.length > 10) {
        history_list.splice(10, history_list.length - 10)
      }
      this.history_list = history_list
      this.$sa0.localStorage.set('POST_HISTORY', history_list)
    },
    copyApiContent() {
      let type = 'POST'
      let content = this.data_list
      let length = 0
      for (let i in content) length++
      let url = `${this.url_list[this.url_choose].url}${this.url_input}`;
      let md = ''
      if (this.post_remark !== '') md += `## ${this.post_remark}` + '\n'
      md += '### 请求URL \n'
      md += '- ` ' + url + ' `\n'
      md += '\n'
      md += '### 请求方式 \n'
      md += '- ' + type + '\n'
      md += '\n'
      if (length > 0) {
        md += '### 请求示例\n'
        let data = {}
        let data_list = this.data_list
        for (let i in data_list) {
          let d = data_list[i].value
          let de_json_arr = ['Array', 'Object'];
          if (de_json_arr.indexOf(data_list[i].type) !== -1) d = JSON.parse(data_list[i].value)
          if (data_list[i].type === 'Boolean') d = !!data_list[i].value
          if (data_list[i].type === 'Number') d = Number(data_list[i].value)
          if (data_list[i].check) data[`${data_list[i].key}`] = d
        }
        md += '``` \n'
        md += `${JSON.stringify(data, null, 4)}\n`
        md += '``` \n'

        md += '### 请求参数说明\n'
        md += '\n'
        md += '|参数名|必选|类型|说明|\n'
        md += '|:----    |:---|:----- |-----   |\n'
        for (let i in content) {
          md += `|${content[i].key} |  ${content[i].check ? '是' : '否'}  | ${content[i].type} |    ${content[i].remark}   |\n`
        }
        md += '\n'
      }
      md += '### 返回示例\n'
      md += '``` \n'
      md += `${this.response_content}\n`
      md += '``` \n'

      if (this.response_table.length !== 0) {
        md += '### 返回示例说明\n'
        this.response_table.map((item) => {
          md += `#### ${item.title.join(' ')}` + '\n'
          md += '|参数名|类型|说明|\n'
          md += '|:----    |:---|:----- |-----   |\n'
          for (let i in item.data) {
            md += `|${item.data[i].key} | ${item.data[i].type} | ${item.data[i].remark} |\n`
          }
          md += '\n'
        })
      }
      return md
    },
    makeResponseTable(data, title) {
      let d = []
      let n = []
      for (let i in data) {
        let t = typeof data[i]
        if (t === 'object') if (Array.isArray(data[i])) t = 'array'
        d.push({
          key: i,
          type: t.slice(0, 1).toUpperCase() + t.slice(1),
          remark: '',
          data: data[i]
        })
        if (t === 'object') n.push(i)
      }
      this.response_table.push({
        title: title,
        data: d
      })
      n.map((item) => {
        let t = JSON.parse(JSON.stringify(title))
        let s = item
        s = s.slice(0, 1).toUpperCase() + s.slice(1)
        t.push(s)
        this.makeResponseTable(data[item], t)
      })
    },
    docTableClick(item, i) {
      if (i.data[0]) {
        let t = JSON.parse(JSON.stringify(item.title))
        let k = i.key
        t.push(k.slice(0, 1).toUpperCase() + k.slice(1))
        this.makeResponseTable(i.data[0], t)
      }
    },
    delResponseTable(key) {
      this.response_table.splice(key, 1)
    },
    postDo() {
      let data = {}
      let data_list = this.data_list
      for (let i in data_list) {
        let d = data_list[i].value
        let de_json_arr = ['Array', 'Object'];
        if (de_json_arr.indexOf(data_list[i].type) !== -1) d = JSON.parse(data_list[i].value)
        if (data_list[i].type === 'Boolean') d = !!data_list[i].value
        if (data_list[i].type === 'Number') d = Number(data_list[i].value)
        if (data_list[i].check) data[`${data_list[i].key}`] = d
      }
      let url = `${this.url_list[this.url_choose].url}${this.url_input}`
      this.$sa0.post({
        url: url,
        data: data
      }).then((response) => {
        this.response = response.data
        this.response_content = JSON.stringify(this.response, null, 4)
        if (typeof this.response.data !== "undefined") {
          this.response_table = []
          this.makeResponseTable(this.response.data, ['Data'])
        }
      })
    },
  }
}
</script>
<template>
  <div class="text-center">
    <QuickData :visible="quick_data_show" :ok="quickDataOk" :cancel="quickDataCancel"></QuickData>
    <a-space align="start">
      <div class="history_wrapper text-left">
        <a-card title="保存记录">
          <a-list item-layout="horizontal" :data-source="history_list">
            <a-list-item slot="renderItem" class="truncate cursor-pointer" slot-scope="item, index">
              <a-list-item-meta @click="historyItemClick(item)"
                                :description="item.remark?item.remark:item.url"></a-list-item-meta>
            </a-list-item>
          </a-list>
        </a-card>
      </div>
      <div class="post_wrapper">
        <a-space compact>
          <a-select class="post_url_select_wrapper" v-model="url_choose">
            <a-select-option v-for="(item, key) in url_list" :key="key" :value="key">
              {{ item.name }}
            </a-select-option>
          </a-select>
          <a-input-search v-model="url_input" class="post_url_input_wrapper" placeholder="input search text"
                          @search="postDo">
            <a-button type="primary" slot="enterButton">
              POST
            </a-button>
          </a-input-search>
          <a-input v-model="post_remark"></a-input>
        </a-space>
        <div>
          <div class="mt-3">
            <div class="layui-btn layui-btn-sm float-right mt-2" @click="quick_data_show = true">快速解析</div>
            <div class="table_title_wrapper text-left">Body</div>
            <table class="layui-table">
              <colgroup>
                <col width="50">
                <col width="140">
                <col width="100">
                <col width="300">
                <col width="200">
                <col>
              </colgroup>
              <thead>
              <tr>
                <th></th>
                <th>键</th>
                <th>类型</th>
                <th>值</th>
                <th>备注</th>
                <th>操作</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, key) in data_list" :key="key">
                <td>
                  <a-checkbox v-model="item.check"></a-checkbox>
                </td>
                <td><input v-model="item.key" class="h-10 w-full" type="text"></td>
                <td>
                  <a-select class="post_url_select_wrapper" v-model="item.type">
                    <a-select-option v-for="(item, key) in value_type" :key="key" :value="item.value">
                      {{ item.title }}
                    </a-select-option>
                  </a-select>
                </td>
                <td><input v-model="item.value" class="h-10 w-full" type="text"></td>
                <td><input v-model="item.remark" class="h-10 w-full" type="text"></td>
                <td class="text-left">
                  <a-space>
                    <div v-if="key !== 0" @click="upDataClick(key)" class="layui-btn layui-btn-sm">上移</div>
                    <div v-if="key !== data_list.length - 1" @click="downDataClick(key)" class="layui-btn layui-btn-sm">
                      下移
                    </div>
                    <div @click="deleteDataClick(key)" class="layui-btn layui-btn-danger layui-btn-sm">删除</div>
                  </a-space>
                </td>
              </tr>
              <tr>
                <td>
                  <a-checkbox v-model="create_data.check"></a-checkbox>
                </td>
                <td><input v-model="create_data.key" class="h-10 w-full" type="text"></td>
                <td>
                  <a-select class="post_url_select_wrapper" v-model="create_data.type">
                    <a-select-option v-for="(item, key) in value_type" :key="key" :value="item.value">
                      {{ item.title }}
                    </a-select-option>
                  </a-select>
                </td>
                <td><input v-model="create_data.value" class="h-10 w-full" type="text"></td>
                <td><input v-model="create_data.remark" class="h-10 w-full" type="text"></td>
                <td class="text-left">
                  <div class="layui-btn layui-btn-sm" @click="createDataClick()">添加</div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div v-for="(item, key) in response_table" :key="key">
            <div class="layui-btn layui-btn-sm layui-btn-warm float-right mt-2" @click="delResponseTable(key)">移除该行
            </div>
            <div class="table_title_wrapper text-left">{{ item.title.join(' ') }}</div>
            <table class="layui-table">
              <colgroup>
                <col width="140">
                <col width="100">
                <col>
                <col width="250">
              </colgroup>
              <thead>
              <tr>
                <th>键</th>
                <th>类型</th>
                <th>备注</th>
                <th>操作</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(i, k) in item.data" :key="k">
                <td><input v-model="i.key" class="h-10 w-full" type="text"></td>
                <td><input v-model="i.type" class="h-10 w-full" type="text"></td>
                <td><input v-model="i.remark" class="h-10 w-full" type="text"></td>
                <td class="text-left">
                  <a-space>
                    <div v-if="k !== 0" @click="upTableClick(key,k)" class="layui-btn layui-btn-sm">上移</div>
                    <div v-if="k !== item.data.length - 1" @click="downTableClick(key,k)"
                         class="layui-btn layui-btn-sm">
                      下移
                    </div>
                    <div @click="deleteTableClick(key,k)" class="layui-btn layui-btn-danger layui-btn-sm">删除</div>
                    <div v-if="i.type === 'Array'" @click="docTableClick(item,i)" class="layui-btn layui-btn-sm">解析
                    </div>
                  </a-space>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="response_wrapper">
        <div>
          <a-textarea class="w-full" v-model="response_content" :auto-size="{ minRows: 26, maxRows: 26 }"/>
        </div>
        <div class="mt-2">
          <div :data-clipboard-text="copyApiContent()" type="primary" @click="copyClick('copy_api')"
               class="layui-btn copy_api w-full">复制并保存 API 文档
          </div>
        </div>
        <div class="mt-2">
          <div :data-clipboard-text="JSON.stringify(response)" type="primary" @click="copyClick('copy_res')"
               class="layui-btn copy_api w-full">复制 RESPONSE
          </div>
        </div>
      </div>
    </a-space>
  </div>
</template>
<style scoped>
.table_title_wrapper {
  font-size: 26px;
  font-weight: bold;
}

.post_url_input_wrapper {
  width: 600px;
}

.post_url_select_wrapper {
  width: 100px;
}

.history_wrapper {
  width: 200px;
}

.post_wrapper {
  width: 900px;
}

.response_wrapper {
  width: 400px;
}
</style>
