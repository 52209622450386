<script>
/***
 Name: quickData
 Code: sa0ChunLuyu
 Time: 2021/10/22 11:56
 Remark: 快速解析Data
 */
export default {
  props: ['visible', 'ok', 'cancel'],
  data() {
    return {
      data_input: '{}',
    }
  },
  mounted() {
  },
  methods: {
    handleOk() {
      this.ok(this.data_input)
    },
    handleCancel() {
      this.cancel()
    },
  }
}
</script>
<template>
  <div>
    <a-modal title="快速解析" :visible="visible" okText="确认" cancelText="取消" @ok="handleOk" @cancel="handleCancel">
      <div>
        <a-textarea :rows="10" v-model="data_input"/>
      </div>
    </a-modal>
  </div>
</template>
<style scoped>

</style>
